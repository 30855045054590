@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli/Muli-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli/Muli-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli/Muli-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli/Muli-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli/Muli-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli/Muli-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli/Muli-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli/Muli-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli/Muli-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli/Muli-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli/Muli-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli/Muli-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli/Muli-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli/Muli-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

